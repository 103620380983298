html {
  --primary-color: #000;
  --primary-background: #ddd;
  --inactive-color: #999;
  --header-color: #fff;
  --header-sub-color: #ddd;
  --header-background: #000;
}

body {
  text-align: center;
  font-family: sans-serif;
  padding: 0;
  margin: 0;
  color: var(--primary-color);
  background: var(--primary-background);
}

a {
  text-decoration: none;
  color: var(--primary-color);
}

.error {
  font-size: xx-large;
  font-weight: bold;
  color: #f00;
}
